import * as React from "react";
import { Link } from "gatsby";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import nihlogo from "../images/NIHlogo823.png";
import biosporelogo from "../images/biosporelogo.png";
import orImg from "../images/or.jpg";
import sfImg from "../images/722SF-feature.jpg";
import aromatherapyImg from "../images/aromatherapy-feature.jpeg";
import sanitizersImg from "../images/sanitizers-feature.jpeg";
import drainflyImg from "../images/drainfly-feature.jpeg";
import drainsImg from "../images/drain-feature.jpeg";

function Features(props) {
  return (
    <>
      <Row>
        <div className="main-feature">
          <a href="https://assets.unitedlabsmdsupply.com/pdf/United-Laboratories-Drain-Maintenance-Biotechnology-White-Paper.pdf" className="overlayLink" target="_blank" rel="noopener noreferrer">
            {" "}
          </a>
          <Image
            className="feature-img"
            src={orImg}
            alt="_"
            title="_"
          />
          <div className="feature-title">New Product Launch</div>
          <div className="feature-text">
            <Image 
              src={nihlogo}
              alt="NIH Logo"
              title="NIH Logo"
            />
            <h6>
            <br />
            White Paper: Published online 28 Dec. 2020
            <br />
            Ways to control harmful biofilms: Prevention, inhibition, and eradication
              <br />
              </h6>
            <Button>Learn More</Button>
          </div>
        </div>
      </Row>
      <Row>
        <div className="main-feature">
          <Link to="/product/united-722-biospor-e-drain-maintenance-treatment-system" className="overlayLink">
            {" "}
          </Link>
          <Image
            className="feature-img"
            src={sfImg}
            alt="722SF Feature"
            title="722SF Feature"
          />
          <div className="feature-title">722SF <Image src={biosporelogo} alt="Biospore Logo" title="Biospore Logo" id="biospore-inline-logo"/></div>
          <div className="feature-text">
            <b>BIOSPOR-E</b> is formulated with a blend of beneficial bacteria strains and
            enzymes that are particularly suited to break down, digest, and control
            buildup in drains caused by organic and human proteins.
            Operating room suction system discharge that is disposed of in drains encourages
            the development of this buildup. If not treated regularly, costly backups may
            occur that can cause exposure to pathogens and bio-hazard fluids. Packaged for
            use with United's SureFlo Dosing System.
          </div>
        </div>
      </Row>
      <Row>
        <div className="main-feature">
          <Link to="/category/disinfectants" className="overlayLink">
            {" "}
          </Link>
          <Image
            className="feature-img"
            src={sanitizersImg}
            alt="Fresh Air Products"
            title="Fresh Air"
          />
          <div className="feature-title">Disinfectants and Sanitizers</div>
          <div className="feature-text">
            Maintain the health and well-being of the people in your facility or
            workplace. United’s MD Supply offers numerous hospital grade
            disinfectants and sanitizers that are also recognized a likely to
            control COVID-19.
          </div>
        </div>
      </Row>
      <Row>
        <div className="main-feature">
          <Link to="/category/deodorants" className="overlayLink">
            {" "}
          </Link>
          <Image
            className="feature-img"
            src={aromatherapyImg}
            alt="Fresh Air Products"
            title="Fresh Air"
          />
          <div className="feature-title">Improve Air Quality</div>
          <div className="feature-text">
            Why just deodorize, when you can have a mini spa experience and
            repel insects with this entire line of FRESH AIR™ technology
            products. Formulated with our unique blend of aromatherapy fragrance
            oils, Fresh Air™ Technology, will provide you with the aromatherapy
            benefits found in peppermint oil.
          </div>
        </div>
      </Row>
      <Row>
        <div className="main-feature">
          <Link
            to="/product/united-547-bio-brigade-drain-membrane-ii"
            className="overlayLink"
          >
            {" "}
          </Link>
          <Image
            className="feature-img"
            src={drainflyImg}
            alt="Fresh Air Products"
            title="Fresh Air"
          />
          <div className="feature-title">Safely Eliminate Drain Flies</div>
          <div className="feature-text">
            All Natural Floor Drain Odor and Insect Control that is Healthy for
            You to Breath. BIO BRIGADE DRAIN MEMBRANE will float on the water to
            seal off odors, provide a fresh peppermint scent, and kill drain
            flies and other insects on contact and through residual control. No
            one will even realize insect control is in use.
          </div>
        </div>
      </Row>
      <Row>
        <div className="main-feature">
          <Link to="/category/drain-maintenance" className="overlayLink">
            {" "}
          </Link>
          <Image
            className="feature-img"
            src={drainsImg}
            alt="Fresh Air Products"
            title="Fresh Air"
          />
          <div className="feature-title">Eliminate Drain Blockages</div>
          <div className="feature-text">
            Reduce blockages and odors with the only drain maintenance system
            specifically designed for dialysis and healthcare facilities. Earth
            Smart® BIO BRIGADE DEVOUR-DX contains a specific blend microbes
            which bio-degrades high volumes of proteins, long-chain fatty acids,
            and bio-film more effectively than any other product on the market.
            Use with Sure-Flo Dosing System for an economical, maintenance-free
            solution.
          </div>
        </div>
      </Row>
    </>
  );
}

export default function IndexPage({ data }) {
  return <Features />;
}
